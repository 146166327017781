<template>
  <div>
    <Filters
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="applyFilters"
    />
    <template v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></template>
    <div v-else-if="!isLoading && noRecord">
      <NoRecordFound />
    </div>
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH
            v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
            v-show="
              showColumnInDataTable(filtersData, head.dataTableName, [
                'Name',
                'Phone Number',
                'Email',
              ])
            "
            :key="head.id"
          >
            <span v-i18n="dashboard">
              {{ head.dataTableName }}
            </span>
          </TH>
          <TH
            v-show="showColumnInDataTable(filtersData, 'Campus', ['Campus'])"
            v-if="!currentCampusScope"
          >
            <span>Campus</span>
          </TH>
          <TH
            v-show="showColumnInDataTable(filtersData, 'Class', ['Class'])"
            v-if="!currentClassScope"
          >
            <span>Class</span>
          </TH>
          <TH
            v-show="showColumnInDataTable(filtersData, 'Section', ['Section'])"
            v-if="!currentSectionScope"
          >
            Section
          </TH>
          <TH v-show="showColumnInDataTable(filtersData, 'Actions')">
            <span v-i18n="dashboard">Actions</span>
          </TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(student, index) in studentList" :key="index">
          <TD v-show="showColumnInDataTable(filtersData, 'Name', ['Name'])">
            <span>
              <SingleUserDisplay :user="student" label="full_name" :image="true" />
            </span>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Phone Number', ['Phone Number'])">
            <DisplayPhoneNumber :phone="student.phone" />
          </TD>
          <!-- add phone number  -->

          <TD v-show="showColumnInDataTable(filtersData, 'Email', ['Email'])" :lowercase="true">
            {{ student.email || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Registration Number')">
            {{ student.student_info.registration_no || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Roll Number')">
            <BadgeDisplay
              :options="student.users_roles_entities"
              :is-object-concated="false"
              label="roll_no"
            />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Gender')">
            {{ student.gender || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Date Of Birth')">
            {{
              (student.profile && student.profile.date_of_birth) || GENERAL_CONSTANTS.NOT_APPLICABLE
            }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Blood Group')">
            {{
              (student.profile && student.profile.blood_group) || GENERAL_CONSTANTS.NOT_APPLICABLE
            }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Address')">
            {{ (student.profile && student.profile.address) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Religion')">
            {{ (student.profile && student.profile.religion) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Main Guardians')">
            <span v-if="!isEmpty(student.student_info.main_guardian)">
              <SingleUserDisplay
                :user="student.student_info.main_guardian"
                label="full_name"
                :image="true"
              />
            </span>
            <span v-else>
              {{ GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </span>
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Other Guardians')">
            <MultiUserDisplay
              :options="student.student_info.guardians"
              label="full_name"
              :image="true"
              :dropdown-top-bottom="dropdownTopBottom(index, studentList, 'bottom-6', 'top-6')"
              :tooltip="dropdownTopBottom(index, studentList, 'tooltip-bottom', 'tooltip-top')"
            />
          </TD>

          <TD v-show="showColumnInDataTable(filtersData, 'Status')">
            <UiStatus
              :title="
                student.login_activated_at
                  ? GENERAL_CONSTANTS.LOGIN_ENABLED
                  : GENERAL_CONSTANTS.LOGIN_DISABLED
              "
              :success="!!student.login_activated_at"
            />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Student Mode')">
            <span
              class="text-primary-purple-600 cursor-pointer"
              @click="redirectToStudentMode(student)"
            >
              Student Mode
            </span>
          </TD>
          <TD
            v-show="showColumnInDataTable(filtersData, 'Campus', ['Campus'])"
            v-if="!currentCampusScope"
          >
            <BadgeDisplay
              :options="student.users_roles_entities"
              :profile="true"
              :is-object-concated="false"
              :nested-label="['campus', 'title']"
            />
          </TD>
          <TD
            v-show="showColumnInDataTable(filtersData, 'Class', ['Class'])"
            v-if="!currentClassScope"
          >
            <span class="capitalize">
              <BadgeDisplay
                :options="student.users_roles_entities"
                :is-object-concated="false"
                :profile="true"
                :nested-label="['class', 'title']"
              />
            </span>
          </TD>
          <TD
            v-show="showColumnInDataTable(filtersData, 'Section', ['Section'])"
            v-if="!currentSectionScope"
          >
            <BadgeDisplay
              :options="student.users_roles_entities"
              :nested-label="['section', 'title']"
              :is-object-concated="false"
            />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Actions')">
            <TableAction
              :current-user="student"
              :idx="index"
              :action-list="student.actionsList"
              @action="studentAction"
            />
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>
    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :key="`Pagination${forceRender}`"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(studentCounts, filteredRecordLimit)"
      @filterRecord="filterRecord"
    />
    <div class="upload-block mt-5 hidden">
      <div class="flex items-center gap-2 mt-2">
        <input
          id="actual-btn"
          ref="studentCSV"
          type="file"
          hidden
          accept=".csv"
          @change="fileUpload($event)"
        />
      </div>
    </div>
    <!-- Bulk Upload Modal -->
    <BulkUploadModal
      v-if="showBulkUploadModal"
      :modal="showBulkUploadModal"
      :bulk-students-list="bulkStudentsList"
      @toggle="toggleBulkUploadModal"
    />
    <!--Student Enrollment Modal -->
    <StudentEnrollmentModal
      v-if="enrollmentModal"
      :modal="enrollmentModal"
      :current-user="currentStudent"
      @toggle="toggleStudentEnrollmentModal"
    />

    <StudentPromotionalModal
      v-if="promotionalModal"
      :modal="promotionalModal"
      :current-user="currentStudent"
      @toggle="toggleStudentpromotionalModal"
    />
    <GuardianAssociationModal
      v-if="associationModal"
      :modal="associationModal"
      :current-user="currentStudent"
      user-type="student"
      @toggle="toggleAssociationModal"
    />
    <!-- Edit Guardaian Modal -->
    <EditGuardianModal
      v-if="editGuardianModal"
      :modal="editGuardianModal"
      :current-user="currentStudent"
      user-type="student"
      @toggle="toggleEditGuardianModal"
    />
    <!-- Add Student Modal -->
    <StudentModal
      v-if="studentModal"
      :modal="studentModal"
      :edit="currentStudent"
      @toggle="toggleStudent"
    />
    <!-- Activate Teacher Modal -->
    <UserEnableModal @success="fetchData" />
    <!-- Reset Password -->
    <ResetPasswordModal @success="fetchData" />
    <!-- Reset Password -->
    <PassedOutModal
      v-if="passedOutModal"
      :modal="passedOutModal"
      :current-student="currentStudent"
      @toggle="togglePassedOutPassword"
    />
    <RoleModal
      v-if="isRoleModal"
      :modal="isRoleModal"
      user-type="Student"
      assigning-role="section_student"
      @toggle="toggleRoleModal"
    />
    <UserDeactivateModal @success="fetchData" />
    <UserDisableModal @success="fetchData" />
    <UIConfirmationModal
      v-if="isSetupPassword"
      heading="User Setup Password Email"
      :message="GENERAL_CONSTANTS.emailSetupMessage"
      button-text="Sent"
      :modal="isSetupPassword"
      :name="currentStudent?.full_name"
      remaining-message=""
      @cancel="toggleUserPasswordSetupConfirmationModal"
      @confirm="sentSetupPasswordEmailHandler"
    />
  </div>
</template>

<script>
import { dropdownTopBottom, removeEmptyKeysFromObject } from '@src/utils/generalUtil.js'
import UserDeactivateModal from '@/src/components/action-components/DeactivateModal.vue'
import { isEmpty } from 'lodash'
import Loader from '@components/BaseComponent/Loader.vue'
import StudentModal from '@router/views/admin/modals/AddStudentModal.vue'
import TableAction from '@/src/components/TableAction.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import BadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'
import StudentEnrollmentModal from '@views/student/enrollment/StudentEnrollmentModal.vue'
import StudentPromotionalModal from '@/src/router/views/student/promotion/StudentPromotionalModal.vue'
import scrollMixin from '@src/mixins/scroll-mixin'
import generalMixins from '@src/mixins/general-mixins.js'
import fileMixins from '@/src/mixins/file-mixins'
import ResetPasswordModal from '@src/components/action-components/ResetPasswordModal.vue'
import EditGuardianModal from '@src/components/action-components/EditGuardianModal.vue'
import PassedOutModal from '@views/student/pass-out/PassedOutModal.vue'
import UserEnableModal from '@src/components/action-components/EnableLoginModal.vue'
import UserDisableModal from '@src/components/action-components/DisableModal.vue'
import Filters from '@views/student/StudentFilters.vue'
import GENERAL_CONSTANTS, { SCOPE_LEVELS } from '@src/constants/general-constants'
import RoleModal from '@views/admin/modals/RolesModal.vue'
import GuardianAssociationModal from '@src/router/views/student/association/StudentGuadrianAssociationModal.vue'
import {
  userLoginStatus,
  redirectToStudentMode,
  getOptionsBasedOnUserStatus,
} from '@src/utils/settings/tenant-user.util.js'
import MultiUserDisplay from '@src/components/UiElements/MultiUserDisplay.vue'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import DisplayPhoneNumber from '@src/components/UiElements/PhoneDisplay.vue'
import { objectDeepCopy, showColumnInDataTable } from '@utils/generalUtil'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import { studentsGraphChart, rightBarStats } from '@/src/router/views/student/students.util.js'
import studentsData from '@/src/router/views/student/students.json'
import BulkUploadModal from '@src/router/views/student/bulk-upload/StudentBulkUploadModal.vue'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import UiStatus from '@src/components/UiElements/UiStatus.vue'
import {
  TableWrapper,
  THead,
  TRHead,
  TH,
  TBody,
  TRBody,
  TD,
} from '@src/components/UiElements/TableElements/index.js'

export default {
  name: 'ClassStudent',
  components: {
    SingleUserDisplay,
    Loader,
    BadgeDisplay,
    StudentModal,
    TableAction,
    Pagination,
    UserEnableModal,
    StudentEnrollmentModal,
    StudentPromotionalModal,
    GuardianAssociationModal,
    ResetPasswordModal,
    EditGuardianModal,
    PassedOutModal,
    Filters,
    RoleModal,
    UserDisableModal,
    DisplayPhoneNumber,
    UserDeactivateModal,
    MultiUserDisplay,
    NoRecordFound,
    UIConfirmationModal,
    BulkUploadModal,
    UiStatus,
    TableWrapper,
    THead,
    TRHead,
    TH,
    TBody,
    TRBody,
    TD,
  },
  page: studentsData.page,
  mixins: [generalMixins, fileMixins, scrollMixin],
  data() {
    return {
      isEmpty,
      attendanceTranslation: 'attendanceTranslation',
      alwaysDisplayColumnsForCSV: studentsData.alwaysDisplayColumnsForCSV,
      tableHeaders: studentsData.tableHeaders,
      defaultSelectedColumns: studentsData.defaultSelectedColumns,
      bulkTableHeader: studentsData.bulkTableHeader,
      templateFileName: 'Student Upload Template',
      templateHeaders: studentsData.templateHeaders,
      GENERAL_CONSTANTS,
      title: 'title',
      dashboard: 'dashboard',
      options: ['Select'],
      studentList: [],
      isLoading: false,
      noRecord: true,
      showBulkUploadModal: false,
      bulkStudentsList: [],
      actionList: null,
      filteredRecordLimit: 10,
      currentStudent: null,
      studentCounts: '',
      showPagination: false,
      enrollmentModal: false,
      promotionalModal: false,
      passedOutModal: false,
      filtersData: {},
      isRoleModal: false,
      associationModal: false,
      editGuardianModal: false,
      forceRender: 0,
      isSetupPassword: false,
    }
  },
  computed: {
    checkUserLoginStatus() {
      return (status) => {
        return userLoginStatus(status)
      }
    },
    isDataListEmpty() {
      return this.studentList && !this.studentList.length
    },
    ...mapState({
      enableLoginModal: (state) => state.layout.enableLoginModal,
      showDeactivateModal: (state) => state.layout.showDeactivateModal,
      showDisableModal: (state) => state.layout.showDisableModal,
      resetPassword: (state) => state.layout.resetPassword,
      currentSectionScope: (state) => state.layout.currentSectionScope,
      currentCampusScope: (state) => state.layout.currentCampusScope,
      currentClassScope: (state) => state.layout.currentClassScope,
      csv_upload: (state) => state.layout.csv_upload,
      studentModal: (state) => state.layout.studentAddModal,
      tabRightBar: (state) => state.layout.tabRightNav,
      showUiModal: (state) => state.layout.showUiModal,
      isDownloadCSV: (state) => state.layout.isDownloadCSV,
    }),
    modalValueChanges() {
      return (
        this.enableLoginModal ||
        this.showDeactivateModal ||
        this.showDisableModal ||
        this.resetPassword
      )
    },
    ...mapGetters('layout', ['isManualPasswordResetAllowed']),
  },

  watch: {
    modalValueChanges: {
      handler(value) {
        if (!value) this.currentStudent = null
      },
    },
    currentSectionScope: {
      handler() {
        this.filterRecord()
      },
    },
    currentCampusScope: {
      handler() {
        this.filterRecord()
      },
    },
    currentClassScope: {
      handler() {
        this.filterRecord()
      },
    },
    csv_upload: {
      handler() {
        this.$refs.studentCSV.click()
      },
    },
    tabRightBar: {
      immediate: true,
      handler(value) {
        if (value) this.fetchStudentStats()
      },
    },
    showUiModal: {
      handler(value) {
        if (value) {
          this.toggleRoleModal()
          this.forceRender++
        }
      },
    },
    isDownloadCSV: {
      handler() {
        this.downloadTemplte(this.templateFileName, this.templateHeaders)
      },
    },
  },

  mounted() {
    this.filterRecord()
    this.setRightBarData()
  },
  methods: {
    dropdownTopBottom,
    redirectToStudentMode,
    showColumnInDataTable,
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.filterRecord()
      this.forceRender++
    },
    fetchData() {
      this.forceRender++
      this.filterRecord()
    },
    toggleRoleModal(payload) {
      this.isRoleModal = !this.isRoleModal
      if (!this.isRoleModal) this.$store.dispatch('layout/setShowUiModal', false)
      if (payload) {
        this.filterRecord()
        this.forceRender++
      }
    },
    toggleBulkUploadModal(payload) {
      this.showBulkUploadModal = false
      if (payload) this.filterRecord()
    },
    isClassesAvailable(student) {
      return (
        student.section_enrollment &&
        student.section_enrollment[0] &&
        student.section_enrollment[0].section.class.title
      )
    },
    isSectionsAvailable(student) {
      return (
        student.section_enrollment &&
        student.section_enrollment[0] &&
        student.section_enrollment[0].section.title
      )
    },
    isCampusesAvailable(student) {
      return (
        student.section_enrollment &&
        student.section_enrollment[0] &&
        student.section_enrollment[0].section.class.campus.title
      )
    },
    fetchStudentStats(payload = { $where: {} }) {
      this.getStudentsStats(payload).then((res) => {
        this.setRightBarData(res)
      })
    },
    /**
     * Create table action list dynamicaaly
     * @param {String} userStaus
     * @return {Array} actionsArray
     */

    togglePassedOutPassword(payload) {
      this.passedOutModal = !this.passedOutModal
      if (payload) this.currentStudent = null
      if (payload === 'fetch') {
        this.filterRecord()
        this.forceRender++
      }
    },
    tableActionList(userStatus) {
      this.actionList = [
        { name: 'Enrollment', basedOn: [SCOPE_LEVELS.ROOT_LEVEL, SCOPE_LEVELS.CAMPUS_LEVEL] },
        {
          name: 'Edit',
          basedOn: [SCOPE_LEVELS.ROOT_LEVEL, SCOPE_LEVELS.CAMPUS_LEVEL, SCOPE_LEVELS.SECTION_LEVEL],
        },
        { name: 'A_GUARDIAN' },
        { name: 'EDIT_GUARDIAN' },
        { name: 'TV_DEACTIVATE_USER' },
        { name: 'Promote' },
        { name: 'Passed Out' },
      ]

      let options = getOptionsBasedOnUserStatus(userStatus, this.isManualPasswordResetAllowed)
      this.actionList = [...this.actionList, ...options]

      return this.actionList
    },
    lowerCase(value) {
      return value.toLowerCase()
    },

    setRightBarData(stats = null) {
      this.setRightbarContent({
        header: {
          title: 'Students',
          buttons: [
            {
              title: 'Add Student',
              classes: ['primary-button-right'],
              action: { name: 'layout/setStudentAddModal' },
            },
            {
              title: 'ADD_EXISTING_USER',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowUiModal' },
            },
            {
              title: 'DOWNLOAD_CSV',
              icon: 'download',
              iconHeight: '18',
              iconWidth: '18',
              classes: ['primary-button-right'],
              action: {
                func: () => this.downloadCSV('studentList'),
              },
              permissions: {
                basedOn: ['section-level'],
              },
            },
            {
              icon: 'cloudUpload',
              iconHeight: '18',
              iconWidth: '18',
              title: 'Student Bulk Upload',
              permissions: {
                basedOn: ['section-level'],
              },
              classes: ['primary-button-right'],
              action: { name: 'layout/setUploadCSV' },
            },
            {
              icon: 'download',
              iconHeight: '18',
              iconWidth: '18',
              title: 'DOWNLOAD_T',
              classes: ['primary-button-right'],
              action: { name: 'layout/setDownloadTemplate' },
            },
          ],
        },
        stats: rightBarStats(),
        chartData: studentsGraphChart(stats),
      })
    },
    filterRecord(range) {
      this.isLoading = true
      let paginationPayload = paginationRangeHandler(range)
      let filterData = { $where: this.filtersData?.$where }
      let payload = { ...paginationPayload, ...filterData }
      removeEmptyKeysFromObject(payload)
      this.getStudentsWithFilters(payload)
        .then((res) => {
          this.noRecord = isEmpty(res?.records)
          this.studentList = objectDeepCopy(res?.records)
          // getting first_name and_last name from JSON and storing them in the full_name key for options in Badge display
          if (this.studentList?.length > 0) {
            this.studentList.forEach((student) => {
              student.actionsList = this.tableActionList(student.login_activated_at)
              student?.student_info?.guardians.forEach((guardian) => {
                guardian.full_name = `${guardian.first_name} ${guardian.last_name}`
              })
              if (student.student_info.main_guardian)
                student.student_info.main_guardian.full_name = `${student.student_info.main_guardian.first_name} ${student.student_info.main_guardian.last_name}`
            })
          }
          this.studentCounts = res?.meta?.total_records
          this.showPagination = this.studentCounts > GENERAL_CONSTANTS.RECORD_LIMIT
          if (this.$route?.name === '/students')
            this.fetchStudentStats({ $where: this.filtersData?.$where })
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    fileUpload(event) {
      const reader = new FileReader()
      reader.readAsText(event.target.files[0])
      reader.onload = (event) => {
        const text = event.target.result
        const data = this.convertCSVToArray(text)
        this.bulkStudentsList = data
        if (this.bulkStudentsList.length) this.showBulkUploadModal = true
        else this.$store.commit('toast/NEW', { message: 'No Student found in file', type: 'error' })
        this.clearUpload()
      }
    },
    clearUpload() {
      this.$refs.studentCSV.value = null
    },
    toggleStudentEnrollmentModal(payload) {
      this.enrollmentModal = !this.enrollmentModal
      if (!this.enrollmentModal) this.currentStudent = null
      if (payload) {
        this.filterRecord()
        this.forceRender++
      }
    },
    toggleStudentpromotionalModal(payload) {
      this.promotionalModal = !this.promotionalModal
      if (!this.promotionalModal) this.currentStudent = null
      if (payload) {
        this.filterRecord()
        this.forceRender++
      }
      if (!this.promotionalModal) this.currentStudent = null
    },
    toggleAssociationModal(payload) {
      this.associationModal = !this.associationModal
      if (!this.associationModal) this.currentStudent = null
      if (payload) {
        this.filterRecord()
        this.forceRender++
      }
      if (!this.associationModal) this.currentStudent = null
    },
    toggleEditGuardianModal(payload) {
      this.editGuardianModal = !this.editGuardianModal
      if (!this.editGuardianModal) this.currentStudent = null
      if (payload) {
        this.filterRecord()
        this.forceRender++
      }
      if (!this.editGuardianModal) this.currentStudent = null
    },
    studentAction(action, current) {
      this.currentStudent = current
      this.$store.commit('layout/SET_CURRENT_SELECTED_USER', this.currentStudent)

      switch (action) {
        case 'Edit':
          this.toggleStudent()
          break
        case 'Enrollment':
          this.toggleStudentEnrollmentModal()
          break
        case 'TV_ACTIVATE_ACCOUNT':
          this.$store.commit('layout/ENABLE_LOGIN_MODAL', true)
          break
        case 'TV_SUSPEND_ACCOUNT':
          this.$store.commit('layout/SET_SHOW_DISABLE_MODAL', true)
          break
        case 'TV_RP':
          this.$store.commit('layout/RESET_PASSWORD_MODAL', true)
          break
        case 'TV_DEACTIVATE_USER':
          this.$store.commit('layout/SET_SHOW_DEACTIVATE_MODAL', true)
          break
        case 'Promote':
          this.toggleStudentpromotionalModal()
          break
        case 'Passed Out':
          this.togglePassedOutPassword()
          break
        case 'A_GUARDIAN':
          this.toggleAssociationModal()
          break
        case 'EDIT_GUARDIAN':
          this.toggleEditGuardianModal()
          break
        case 'TV_RE':
          this.toggleUserPasswordSetupConfirmationModal()
          break
      }
    },
    toggleUserPasswordSetupConfirmationModal() {
      this.isSetupPassword = !this.isSetupPassword
      if (!this.isSetupPassword) this.currentStudent = null
    },

    async sentSetupPasswordEmailHandler() {
      let payload = { user_id: this.currentStudent?.id }
      await this.resendUserSetupPassword(payload)
      this.toggleUserPasswordSetupConfirmationModal()
    },
    toggleStudent(payload) {
      this.setStudentAddModal()
      if (payload) {
        this.filterRecord()
        this.forceRender++
      }
      if (!this.studentModal) this.currentStudent = null
    },
    ...mapActions('student', [
      'loadClassStudents',
      'seedStudent',
      'getStudentsWithFilters',
      'getStudentsStats',
    ]),
    ...mapActions('layout', ['setUploadCSV', 'setRightbarContent', 'setStudentAddModal']),
    ...mapActions('users', ['uploadUserImage']),
    ...mapActions('auth', ['resendUserSetupPassword']),
  },
}
</script>

<style lang="scss" scoped>
.error {
  background: var(--bg-danger-light) !important;
}
.active {
  .step-counter {
    color: var(--white);
    background-color: var(--primary-green);
    border-color: var(--primary-green);
    box-shadow: 0 3px 14px var(--primary-green);
  }
  .step-info {
    color: var(--text-color);
  }
  .csv-table {
    td,
    th {
      min-width: 100px;
    }
  }
}
</style>
